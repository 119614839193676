import React, { useState } from 'react'
import s from './Questions.module.sass'

function Questions() {

    const questions = [
        {
            question: "Как подать заявку на участие в рейтинге?",
            answer: "Для подачи заявки нужно создать учетную запись в личном кабинете, заполнить анкету участника и загрузить необходимые материалы, такие как фото и видео проектов."
        },
        {
            question: "Каковы преимущества участия в рейтинге?",
            answer: "Участие в рейтинге предоставляет возможность получить признание в индустрии, привлечь внимание к вашему проекту и расширить деловые связи. Победители получат ценные призы и возможности для дальнейшего сотрудничества."
        },
        {
            question: "Что делать, если возникли технические проблемы при подаче заявки?",
            answer: "Если вы столкнулись с техническими трудностями при подаче заявки, свяжитесь с нашей службой поддержки через форму обратной связи на сайте или по электронной почте."
        },
        {
            question: "Какие номинации доступны и можно ли податься на несколько номинаций?",
            answer: "Наш сайт предлагает различные номинации, и вы можете подать заявку в нескольких номинациях, если ваш проект соответствует их критериям. Ознакомьтесь с полным списком номинаций и их требованиями в соответствующем разделе сайта."
        },
        {
            question: "Какие критерии отбора и оценки номинантов?",
            answer: "Мы оцениваем номинантов по ряду критериев, включая креативность, инновации, качество реализации и соответствие тематике рейтинга. Подробные критерии и порядок оценки можно найти в разделе Жюри и критерии"
        },
        {
            question: "Какие требования к материалам и их форматам?",
            answer: "Материалы, подаваемые вместе с заявкой, должны быть в высоком качестве и соответствовать формату (JPEG, MP4, PDF и т.д.). Подробности о форматах и требованиях указаны на странице подачи заявки."
        },
        {
            question: "Какие сроки подачи заявки и финальные даты этапов рейтинга?",
            answer: "Сроки подачи заявки и этапы рейтинга указаны в разделе Даты на нашем сайте. Пожалуйста, следите за обновлениями, чтобы не пропустить важные даты."
        },
        {
            question: "Сколько стоит участие в рейтинге?",
            answer: "Стоимость подачи на одну номинацию составляет 100 000 тенге."
        },
        {
            question: "Как я могу оплатить участие в рейтинге?",
            answer: "Оплатить можно через личный кабинет с помощью банковской карты или другого доступного способа оплаты. Все детали по оплате отображаются на странице регистрации."
        },
    ]
    const [opened, setOpened] = useState()

    const handleOpenQuestion = (e, index) => {
        if(opened == index){
            setOpened(null)
        }else{
            setOpened(index)
        }
    }


  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <h1 className={s.title}>ВОПРОСЫ И ОТВЕТЫ</h1>
            <div className={s.questions}>
                {
                    questions && questions.map((elem, index) => 
                    <div key={index} className={s.questionBlock} onClick={(e) => handleOpenQuestion(e, index)}>
                        <div className={s.question}>
                            <div className={s.text}>{elem.question}</div>
                            <img src={opened != index ? "/images/ph_plus-light.svg" : "/images/ph_plus-light (2).svg"} alt="" />
                        </div>
                        <div style={opened == index ? {display: 'flex'} : {display: "none"}} className={s.answer}>{elem.answer}</div>
                    </div>

                    )
                }
            </div>
        </div>
    </div>
  )
}

export default Questions